<template>
  <div>
    <section role="main" class="empty">
      <div class="container">
        <div class="row icon-tabs">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <router-link to="/executive" class="nav-link"
                ><img src="/img/icons/executives.png" alt="Executives" />
                Executives</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/company" class="nav-link"
                ><img src="/img/icons/companies.png" alt="Companies" />
                Companies</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/news" class="nav-link"
                ><img src="/img/icons/deals.png" alt="News" /> News</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/deals-transactions" class="nav-link"
                ><img src="/img/icons/deals.png" alt="Deals & Transactions" />
                Deals & Transactions</router-link
              >
            </li>
            <li class="nav-item ">
              <router-link to="/listing" class="nav-link active"
                ><img src="/img/book.png" style="height: 23px" />
                Listing</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/commentaries" class="nav-link"
                ><img src="/img/icons/commentaries.png" alt="Commentaries" />
                Commentaries</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/interesting-bites" class="nav-link"
                ><img
                  src="/img/icons/interesting.png"
                  alt="Interesting Bites"
                />
                Interesting Bites</router-link
              >
            </li>
            <!-- NOTE: Temporary hide the open position tab. -->
            <!-- <li class="nav-item">
              <router-link to="/open-positons" class="nav-link"
                ><img src="/img/icons/position.png" alt="Open Position" /> Open
                Positons</router-link
              >
            </li> -->
          
          </ul>
          <div class="tab-content mb-3">
            <div>
              <div class="col-12 row">
                <div class="col-2">
                  <img
                    :title="
                      detail.publication && detail.publication.name
                        ? detail.publication.name
                        : ''
                    "
                    :src="
                      detail.publication && detail.publication.publication_logo
                        ? detail.publication.publication_logo
                        : '/img/new.png'
                    "
                    alt=""
                    height="100"
                    width="100"
                  />
                </div>
                <div class="col-10 text-start">
                  <h3>{{detail.name ? detail.name :'' }}</h3>
                  <h5 style="font-size: 12px">
                    {{
                      detail.publication && detail.publication.name
                        ? $helperService.startsWithCapital(detail.publication.name)
                        : ""
                    }}
                  </h5>
                  <a
                    style="color: blue; cursor: pointer; font-size: 12px"
                    v-on:click="openLink(detail.source_url)"
                    >Source</a
                  >
                </div>
              </div>
              <hr />
              <div class="row mt-4" v-if="detail.type == 'COMPANY'">
                <div
                  class="col-lg-4 col-sm-4"
                  v-for="(data, index) in list"
                  :key="index"
                >
                  <div class="rectangle-box">
                    <div class="row" style="height: 115px">
                      <div class="col-lg-3 col-sm-3 col-1 pr-2 text-center">
                        <a>
                          <img
                            v-if="data.company_logo"
                            :src="
                              data.company_logo
                                ? data.company_logo
                                : '/img/user-pic.png'
                            "
                            alt=""
                            class="round"
                          />
                          <img
                            v-if="!data.company_logo"
                            :src="
                              data.company_logo
                                ? data.company_logo
                                : '/img/company.png'
                            "
                            alt=""
                            class="round"
                          />
                        </a>
                        <a
                          class="mr-1"
                          style="margin-left: -3px"
                          :title="data.country ? data.country.country_name : ''"
                        >
                          <country-flag
                            :country="
                              data.country ? data.country.country_code : 'US'
                            "
                          />
                        </a>
                      </div>
                      <div class="col-lg-7 col-sm-7 col-9 px-2">
                        <router-link :to="'/company-detail/' + data.id">
                          <div
                            style="display: initial !important"
                            :title="data.company_name ? data.company_name : '-'"
                            class="client-name"
                          >
                            {{ data.company_name ? data.company_name : "-" }}
                          </div>
                          <div
                            class="address"
                            style="display: initial !important"
                          >
                            <div
                              :title="
                                data.industry ? data.industry.industry_name : ''
                              "
                            >
                              {{
                                data.industry
                                  ? $helperService.getText(
                                      data.industry.industry_name,
                                      20
                                    )
                                  : ""
                              }}
                            </div>

                            <div>
                              {{
                                data.city
                                  ? $helperService.getText(
                                      data.city.city_name,
                                      30
                                    )
                                  : ""
                              }}
                              {{
                                data.country
                                  ? $helperService.getText(
                                      data.country.country_name,
                                      30
                                    )
                                  : ""
                              }}
                            </div>
                            <div>
                              {{
                                data.company_type
                                  ? $helperService.startsWithCapital(data.company_type) + " Company"
                                  : "-"
                              }}
                            </div>
                            <div>
                              Employee Size:
                              {{ data.company_size ? data.company_size : "-" }}
                            </div>
                          </div>
                        </router-link>
                      </div>
                      <div class="col-lg-2 col-sm-2 col-2 px-2 text-center">
                        <!-- <span v-if="data.is_fav == 0"
                          ><img
                            v-on:click="addToFavorite(data)"
                            src="/img/favorite.png"
                            width="18"
                            height="18"
                            alt="Favorite"
                            style="opacity: 0.5"
                        /></span>
                        <span v-if="data.is_fav == 1"
                          ><img
                            v-on:click="addToFavorite(data)"
                            src="/img/favorite-fill.png"
                            width="18"
                            height="18"
                            alt="Favorite Fill"
                        /></span> -->
                        <input
                          type="checkbox"
                          v-on:click="addToFavorite(data)"
                          :checked="data.is_fav == 1"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12 col-sm-12">
                      <span class="update-date"
                        >Last Updated :
                        {{
                          $helperService.getFormatedDate(data.updated_on)
                        }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-4" v-if="detail.type == 'EXECUTIVE'">
                <div
                  class="col-lg-4 col-sm-4"
                  v-for="(data, index) in list"
                  :key="index"
                >
                  <div class="rectangle-box">
                    <div class="row" style="height: 115px">
                      <div class="col-lg-3 col-sm-3 col-1 pr-2 text-center">
                        <a>
                          <img
                            v-if="data.executive_logo || !data.gender"
                            :src="
                              data.executive_logo
                                ? data.executive_logo
                                : '/img/user-pic.png'
                            "
                            alt=""
                            class="round"
                          />
                          <img
                            v-if="data.gender == 'male' && !data.executive_logo"
                            :src="
                              data.executive_logo
                                ? data.executive_logo
                                : '/img/male.png'
                            "
                            alt=""
                            class="round"
                          />
                          <img
                            v-if="
                              data.gender == 'female' && !data.executive_logo
                            "
                            :src="
                              data.executive_logo
                                ? data.executive_logo
                                : '/img/female.png'
                            "
                            alt=""
                            class="round"
                          />
                        </a>
                        <br />
                        <a
                          class=""
                          style="margin-left: -6px"
                          :title="data.country ? data.country.country_name : ''"
                        >
                          <country-flag
                            :country="
                              data.country ? data.country.country_code : '-'
                            "
                          />
                        </a>
                      </div>
                      <div
                        class="col-lg-7 col-sm-7 col-9 px-2"
                        style="max-height: 120px"
                      >
                        <router-link :to="'/executive-detail/' + data.id">
                          <div
                            style="display: initial !important"
                            :title="
                              data.executive_name ? data.executive_name : '-'
                            "
                            class="client-name"
                          >
                            {{
                              data.executive_name ? data.executive_name : "-"
                            }}
                          </div>
                          <div
                            :title="
                              data.new_company
                                ? data.new_company.company_name
                                : '-'
                            "
                            class="company-name"
                          >
                            <img
                              :src="
                                data.new_company
                                  ? data.new_company.company_logo
                                  : '/img/user-pic.png'
                              "
                              style="border-radius: 9px"
                              alt=""
                              width="18"
                              height="18"
                            />
                            {{
                              data.new_company
                                ? data.new_company.company_name
                                : "-"
                            }}
                          </div>
                          <div
                            :title="
                              $helperService.isStringOnlyAlphabet(
                                data.new_job_title_id
                              )
                                ? data.new_job_title_id
                                : data.new_job_title
                                ? data.new_job_title.title
                                : ''
                            "
                            class="designation"
                          >
                            <span>{{
                              $helperService.isStringOnlyAlphabet(
                                data.new_job_title_id
                              )
                                ? data.new_job_title_id
                                : data.new_job_title
                                ? data.new_job_title.title
                                : "-"
                            }}</span>
                          </div>
                        </router-link>
                        <div class="mt-2">
                          <a
                            :title="
                              data.country != null && data.country.phone_code
                                ? '+' +
                                  data.country.phone_code +
                                  data.executive_phone
                                : ''
                            "
                            target="__blank"
                            :href="
                              'https://api.whatsapp.com/send?phone=' +
                              (data.country != null && data.country.phone_code
                                ? data.country.phone_code + data.executive_phone
                                : '')
                            "
                            style="cursor: pointer"
                            class="mr-1"
                            ><img
                              src="/img/icons/phone.png"
                              height="25"
                              width="25"
                              alt="Linkedin"
                          /></a>
                          <a
                            :href="'mailto:' + data.executive_email_work"
                            :title="data.executive_email_work"
                            class="mr-1"
                            style="cursor: pointer"
                            ><img
                              src="/img/icons/mail.png"
                              height="25"
                              width="25"
                          /></a>
                          <a
                            v-if="data.linkedin_url"
                            v-on:click="openLink(data.linkedin_url)"
                            :title="data.linkedin_url"
                            style="cursor: pointer"
                            class="mr-1"
                            ><img
                              style="border-radius: 15px"
                              src="/img/linkedin-color.png"
                              height="25"
                              width="25"
                              alt="Linkedin"
                          /></a>
                          <a
                            v-if="data.twitter_url"
                            v-on:click="openLink(data.twitter_url)"
                            style="cursor: pointer"
                            :title="data.twitter_url"
                            ><img
                              src="/img/twitter.png"
                              height="25"
                              width="25"
                              alt="Twitter"
                          /></a>
                        </div>
                      </div>
                      <div class="col-lg-2 col-sm-2 col-2 px-2 text-center">
                        <input
                          type="checkbox"
                          v-on:click="addToFavorite(data)"
                          :checked="data.is_fav == 1"
                        />
                      </div>
                    </div>
                    <div class="col-lg-12 col-sm-12">
                      <span class="update-date"
                        >Last Updated :
                        {{
                          $helperService.getFormatedDate(data.updated_on)
                        }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ErrorComponent @retry="getList(0)" ref="errorComponent" />
            <div class="row mx-0 mb-4">
              <div class="col-lg-12 mt-5 text-center">
                <div class="page-bottom-pagination text-right">
                  <Pagination
                    @page-change="pageChange"
                    @items-per-page-change="itemsPerPageChange"
                    ref="listPagination"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import CountryFlag from "vue-country-flag-next";
export default {
  name: "Executive",
  components: {
    ErrorComponent,
    Pagination,
    CountryFlag,
  },
  data() {
    return {
      id: this.$route.params.id,
      loader: true,
      offset: -1,
      searchObj: {},
      detail: {
        type: "",
      },
      list: [],
    };
  },
  mounted() {
    if (this.id) {
      this.getListingdetail(this.id);
    }
  },
  methods: {
    addToFavorite(data) {
      var obj = {};

      var url = "";
      if (this.detail.type == "EXECUTIVE") {
        url = "/add/executive/fav";
        obj.executive_id = data.id;
      }
      if (this.detail.type == "COMPANY") {
        url = "/add/company/fav";
        obj.company_id = data.id;
      }
      this.$api
        .putAPI({
          _action: url,
          _body: obj,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          return (data.is_fav = data.is_fav == 1 ? 0 : 1);
          // this.getList(0);
          // this.$router.push("/company");
        })
        .catch(() => {});
    },
    openLink(url) {
      window.open(url);
    },
    getListingdetail(id) {
      this.$api
        .getAPI({
          _action: "/cluster/" + id,
        })
        .then((res) => {
          this.detail = res;
          this.getList(0);
        })
        .catch(() => {});
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    getList(page) {
      this.$refs.errorComponent.updateListLoader(true);
      this.list = [];
      this.searchObj.offset = page;
      if (this.detail.type) {
        this.searchObj.type = this.detail.type;
      }
      this.$api
        .getAPI({
          _action: "/mention/listing/pagination/list/" + this.id,
          _body: this.searchObj,
        })
        .then((res) => {
          this.list = res.list;
          this.offset = res.next_offset;
          this.loader = false;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            if (this.detail.type == "COMPANY") {
              this.$refs.errorComponent.setData(
                res,
                "No Mention Company are available"
              );
            }
            if (this.detail.type == "EXECUTIVE") {
              this.$refs.errorComponent.setData(
                res,
                "No Mention Executive are available"
              );
            }
            this.$refs.errorComponent.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
  },
};
</script>
