<template>
  <div class="empty">
    <div class="container">
      <div class="row">
        <div class="col-lg-11 col-sm-12">
          <!-- <nav aria-label="breadcrumb">
            <ol class="breadcrumb px-0">
              <li class="breadcrumb-item" style="cursor: pointer">
                <a v-on:click="$router.push('/company')">Home</a>
              </li>
              <li
                style="cursor: pointer"
                class="breadcrumb-item active"
                aria-current="page"
              >
                companies
              </li>
            </ol>
          </nav> -->
        </div>
        <div class="col-lg-1 col-sm-1 col-2 text-right">
          <span>
            <input
              type="checkbox"
              v-on:click="addToFavorite(detail)"
              :checked="detail.is_fav == 1"
          /></span>
        </div>
      </div>
    </div>
    <div class="full-rectangle mt-0">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 col-sm-8">
            <div class="col-lg-8 col-sm-8 px-1 mb-4 ml-4">
              <div class="row">
                <div class="col-lg-3 col-sm-3 col-2 px-2 text-center row">
                  <a>
                    <img
                      v-if="detail.company_logo"
                      :src="
                        detail.company_logo
                          ? detail.company_logo
                          : '/img/company.png'
                      "
                      alt=""
                      style="width: 100%"
                      height="120"
                    />
                    <img
                      v-if="!detail.company_logo"
                      :src="
                        detail.company_logo
                          ? detail.company_logo
                          : '/img/company.png'
                      "
                      class="img-fluid"
                      height="1000"
                    />
                  </a>
                </div>
                <div class="col-lg-9 col-sm-9 col-9 px-1 ml-2">
                  <div>
                    <h5
                      style="cursor: pointer"
                      v-on:click="
                        detail.company_website
                          ? openLink(detail.company_website)
                          : ''
                      "
                    >
                      {{ detail.company_name }}&nbsp;

                      {{
                        detail.ticker
                          ? " - (" +
                            $helperService.getUpperCase(detail.ticker) +
                            ")"
                          : ""
                      }}
                    </h5>
                  </div>

                  <div class="calibri">
                    {{ detail.industry ? detail.industry.industry_name : "-"
                    }}<br />
                    {{ detail.city ? detail.city.city_name + "," : "" }}
                    {{ detail.country ? detail.country.country_name : ""
                    }}<br />
                    {{
                      detail.company_type
                        ? detail.company_type + " Company"
                        : "-"
                    }}
                    <br />
                    Employee Size : {{ detail.company_size }}
                  </div>
                </div>
                <!-- <div>
                  <span>
                    <input
                      type="checkbox"
                      v-on:click="addToFavorite(detail)"
                      :checked="detail.is_fav == 1"
                  /></span>
                </div> -->
              </div>
            </div>
            <div class="col-8 row mb-2" style="display: block ruby">
              <div class="mr-1">
                <a
                  class=""
                  :title="detail.country ? detail.country.country_name : ''"
                >
                  <country-flag
                    :country="
                      detail.country ? detail.country.country_code : 'US'
                    "
                  />
                </a>
              </div>
              <!-- :href="
                    'https://api.whatsapp.com/send?phone=' +
                    (detail.country != null && detail.country.phone_code
                      ? detail.country.phone_code + detail.company_mobile
                      : '')
                  " -->
              <div class="mr-1">
                <a
                  target="__blank"
                  v-show="detail.company_mobile"
                  :title="
                    detail.country != null && detail.country.phone_code
                      ? '+' + detail.country.phone_code + detail.company_mobile
                      : ''
                  "
                  style="cursor: pointer"
                  class=""
                  ><img
                    src="/img/icons/phone.png"
                    height="25"
                    width="25"
                    alt="Linkedin"
                /></a>
              </div>
              <div class="mr-1">
                <a
                  v-show="detail.company_email"
                  :href="'mailto:' + detail.company_email"
                  :title="detail.company_email"
                  style="cursor: pointer"
                  ><img src="/img/icons/mail.png" height="25" width="25"
                /></a>
              </div>
              <div class="mr-1">
                <a
                  v-show="detail.linkedin_url"
                  v-on:click="openLink(detail.linkedin_url)"
                  :title="detail.linkedin_url"
                  style="cursor: pointer"
                  ><img
                    style="border-radius: 15px"
                    src="/img/linkedin-color.png"
                    alt="Linkedin"
                    height="25"
                    width="25"
                /></a>
              </div>
              <div class="mr-1">
                <a
                  v-show="detail.twitter_url"
                  v-on:click="openLink(detail.twitter_url)"
                  :title="detail.twitter_url"
                  style="cursor: pointer"
                  ><img
                    src="/img/twitter.png"
                    height="25"
                    width="25"
                    alt="Twitter"
                /></a>
              </div>
              <div class="mr-1">
                <a
                  v-show="detail.facebook_url"
                  v-on:click="openLink(detail.facebook_url)"
                  :title="detail.facebook_url"
                  style="cursor: pointer"
                  ><img
                    src="/img/facebook.png"
                    height="25"
                    width="25"
                    alt="Linkedin"
                /></a>
              </div>

              <div class="mr-1">
                <a
                  v-show="detail.company_website"
                  v-on:click="openLink(detail.company_website)"
                  :title="detail.company_website"
                  style="cursor: pointer"
                  ><img
                    src="/img/web.png"
                    height="26"
                    width="26"
                    alt="company_website"
                /></a>
              </div>
              <div class="mr-1">
                <a
                  v-show="detail.team_page_url"
                  v-on:click="openLink(detail.team_page_url)"
                  :title="detail.team_page_url"
                  style="cursor: pointer"
                  ><img
                    src="/img/Picture1.png"
                    height="25"
                    width="25"
                    alt="team_page_url"
                /></a>
              </div>
            </div>
            <div class="gery-box" v-if="detail.company_desc">
              <div class="title clearfix pb-2 calibri">
                Company Profile
                <!-- <span class="pull-right"
                  ><img src="/img/plus.png" alt=""
                /></span> -->
              </div>
              <p class="calibri" v-if="detail.company_desc.length <= 200">
                {{ detail.company_desc }}
              </p>
              <p class="calibri" v-if="detail.company_desc.length > 200">
                {{ formattedBody }}
                <a
                  class="link cursor-pointer"
                  v-on:click="showingFullText = !showingFullText"
                >
                  {{ showingFullText ? "Read less" : "Read more" }}
                </a>
              </p>
            </div>

            <div class="row mt-5">
              <div class="col-lg-4 col-sm-4">
                <div class="sm-box" style="height: 270px">
                  <div class="graph-title" style="font-weight: bold">
                    Executive Moves (YoY)
                  </div>
                  <canvas
                    id="bar-chart"
                    width="450"
                    height="450"
                    class="mt-3 ml-3"
                  >
                  </canvas>
                </div>
              </div>
              <div class="col-lg-4 col-sm-4">
                <div class="sm-box" style="height: 270px">
                  <div class="graph-title" style="font-weight: bold">
                    Executive Moves By Functions
                  </div>
                  <canvas
                    id="bar-chart5"
                    width="450"
                    height="450"
                    class="mt-3 ml-3"
                  >
                  </canvas>
                </div>
              </div>
              <div class="col-lg-4 col-sm-4">
                <div class="sm-box" style="height: 270px">
                  <div class="graph-title" style="font-weight: bold">
                    Executive Moves By Region
                  </div>
                  <canvas
                    id="bar-chart3"
                    width="450"
                    height="450"
                    class="mt-3 ml-3"
                  >
                  </canvas>
                </div>
              </div>
            </div>
            <div class="gery-box mt-5" v-if="is_headquater || (regions && regions.length > 0)">
              <div>
                <div class="title clearfix pb-2">
                  Regional Leadership
                </div>
                <div>
                  <button
                    v-if="is_headquater"
                    key="region.id"
                    @click="appliedFilterOnExecutive('HQ', region.id)"
                    class="button-style-custom"
                  >
                    HQ
                  </button>
                  <button
                    v-for="(region) in regions"
                    :key="region.id"
                    @click="appliedFilterOnExecutive('REGION', region.id)"
                    class="button-style-custom"
                  >
                    {{ region.region_name }}
                  </button>
                </div>
              </div>
            </div>
            <div class="gery-box mt-5" v-if="job_functions && job_functions.length > 0">
              <div>
                <div class="title clearfix pb-2">
                  Functional Leadership
                </div>
                <div>
                  <button
                    v-for="(job_fun) in job_functions"
                    :key="job_fun.id"
                    @click="appliedFilterOnExecutive('JOB_FUNCTION', job_fun.id)"
                    class="button-style-custom"
                  >
                    {{ job_fun.job_function_name }}
                  </button>
                </div>
              </div>
            </div>
            <div class="gery-box mt-5" v-if="topics && topics.length > 0">
              <div>
                <div class="title clearfix pb-2">
                  Interesting Bites
                </div>
                <div>
                  <button
                    v-for="(topic) in topics"
                    :key="topic.id"
                    @click="$router.push('/company-executive-bites/' + this.id + '/' + topic.id)"
                    class="button-style-custom"
                  >
                    {{ topic.topic_name }}
                  </button>
                </div>
              </div>
            </div>
            <div v-show="executive_count_company_wise.length > 0" class="gery-box mt-5" >
              <div>
                <div class="title clearfix pb-2">Majorly Hiring From</div>
                <canvas id="hiringGraph"></canvas>
              </div>
            </div>
            <div
              class="gery-box mt-5"
              v-show="structureList && structureList.length > 0"
            >
              <div class="title clearfix pb-2">Organization Structure</div>
              <div class="col-12 row" style="padding: 0; margin: 0">
                <div
                  v-for="(data, index) in structureList"
                  :key="index"
                  class="col-6 white-box row"
                  style="padding: 0; margin: 0"
                >
                  <div
                    class="col-5 text-center"
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      padding: 0 !important;
                      margin: 0 !important;
                    "
                  >
                    <img
                      v-on:click="$router.push('/executive-detail/' + data.id)"
                      :title="data.executive_name ? data.executive_name : ''"
                      :src="
                        data.executive_logo
                          ? data.executive_logo
                          : data.gender == 'male'
                          ? '/img/male.png'
                          : data.gender == 'female'
                          ? '/img/female.png'
                          : '/img/user-pic.png'
                      "
                      alt=""
                      style="cursor: pointer"
                      class="sm2-img mt-3"
                    />

                    <span
                      :title="
                        $helperService.isStringOnlyAlphabet(
                          data.new_job_title_id
                        )
                          ? data.new_job_title_id
                          : data.new_job_title
                          ? data.new_job_title.title
                          : ''
                      "
                      style="
                        font-size: 11px;
                        margin-top: 13px;
                        cursor: pointer;
                        width: 110% !important;
                      "
                      >{{
                        $helperService.isStringOnlyAlphabet(
                          data.new_job_title_id
                        )
                          ? data.new_job_title_id
                          : data.new_job_title
                          ? data.new_job_title.title
                          : ""
                      }}</span
                    >
                  </div>
                  <label
                    class="col-2 text-center"
                    style="
                      font-size: 10px;
                      align-self: center;
                      padding: 0 !important;
                      margin: 0 !important;
                    "
                    >Reports To</label
                  >
                  <div
                    class="col-5 text-center"
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      padding: 0 !important;
                      margin: 0 !important;
                    "
                  >
                    <img
                      v-on:click="
                        $router.push('/executive-detail/' + data.report_to.id)
                      "
                      :title="
                        data.report_to && data.report_to.executive_name
                          ? data.report_to.executive_name
                          : ''
                      "
                      :src="
                        data.report_to && data.report_to.executive_logo
                          ? data.report_to.executive_logo
                          : data.report_to && data.report_to.gender == 'male'
                          ? '/img/male.png'
                          : data.report_to && data.report_to.gender == 'female'
                          ? '/img/female.png'
                          : '/img/user-pic.png'
                      "
                      alt=""
                      height="100"
                      width="100"
                      style="cursor: pointer"
                      class="sm2-img mt-3"
                    />
                    <span
                      :title="
                        data.report_to && data.report_to.new_job_title_id
                          ? $helperService.isStringOnlyAlphabet(
                              data.report_to.new_job_title_id
                            )
                            ? data.report_to.new_job_title_id
                            : data.report_to.new_job_title.title
                          : ''
                      "
                      style="
                        font-size: 11px;
                        margin-top: 13px;
                        cursor: pointer;
                        width: 110% !important;
                      "
                      >{{
                        data.report_to && data.report_to.new_job_title_id
                          ? $helperService.isStringOnlyAlphabet(
                              data.report_to.new_job_title_id
                            )
                            ? data.report_to.new_job_title_id
                            : data.report_to.new_job_title.title
                          : ""
                      }}</span
                    >
                  </div>
                </div>
              </div>
              <div
                class="col-12 title clearfix pb-2 mt-2"
                style="display: flex; justify-content: end"
              >
                <a
                  v-if="structure_count >= 4"
                  style="cursor: pointer"
                  v-on:click="$router.push('/view-structure/' + this.id)"
                  class="pull-right font16"
                  >View More</a
                >
              </div>
            </div>
            <div
              class="gery-box mt-5"
              v-show="dealsList && dealsList.length > 0"
            >
              <div class="title clearfix pb-2">Deals & Acquisitions</div>
              <div v-for="(data, index) in dealsList" :key="index">
                <div class="row pb-2" v-if="index <= 4">
                  <div
                    v-if="data.is_company_logo == 0"
                    v-on:click="openLink(data.news_url)"
                    style="cursor: pointer"
                    class="col-lg-3 col-sm-3 my-auto"
                  >
                    <img
                      :title="
                        data.publication && data.publication.name
                          ? data.publication.name
                          : ''
                      "
                      :src="
                        data.publication && data.publication.publication_logo
                          ? data.publication.publication_logo
                          : ''
                      "
                      alt=""
                      height="100"
                      width="100"
                      class="md-size"
                    />
                  </div>
                  <div
                    v-if="data.is_company_logo == 1"
                    v-on:click="openLink(data.news_url)"
                    style="cursor: pointer"
                    class="col-lg-3 col-sm-3 my-auto"
                  >
                    <img
                      :title="
                        data.primary_company &&
                        data.primary_company.company_name
                          ? data.primary_company.company_name
                          : ''
                      "
                      :src="
                        data.primary_company &&
                        data.primary_company.company_logo
                          ? data.primary_company.company_logo
                          : '/img/user-pic.png'
                      "
                      alt=""
                      height="100"
                      width="100"
                      class="md-size"
                    />
                  </div>
                  <div class="col-lg-9 col-sm-9 my-auto calibri">
                    {{ data.headline }}
                  </div>
                  <hr />
                </div>
              </div>
              <div
                class="col-12 title clearfix pb-2"
                style="display: flex; justify-content: end"
              >
                <a
                  v-if="dealsList.length > 5"
                  style="cursor: pointer"
                  class="pull-right font16"
                  v-on:click="showDetails('DEALS')"
                  >View More</a
                >
              </div>
            </div>
            <div
              class="gery-box mt-3"
              v-show="
                investedCompanyExecutiveList &&
                investedCompanyExecutiveList.length > 0
              "
            >
              <div class="title clearfix pb-2">Investments & Stakeholding</div>

              <div class="row">
                <div
                  style="width: 70px; margin-left: 4px"
                  v-for="(data, index) in investedCompanyExecutiveList"
                  :key="index"
                >
                  <a style="cursor: pointer" v-on:click="goTo(data.news_url)">
                    <img
                      :title="data.name ? data.name : ''"
                      :src="data.logo ? data.logo : '/img/user-pic.png'"
                      height="100"
                      width="100"
                      class="md-size"
                      style="border-radius: 30px"
                  /></a>
                </div>
              </div>
            </div>
            <div class="gery-box mt-3" v-show="newsList && newsList.length > 0">
              <div class="title clearfix pb-2">Featured News</div>
              <div v-for="(data, index) in newsList" :key="index">
                <div class="row pb-2" v-if="index <= 4">
                  <div
                    v-if="data.is_company_logo == 1"
                    class="col-lg-3 col-sm-3 my-auto"
                    style="cursor: pointer"
                    v-on:click="openLink(data.news_link)"
                  >
                    <img
                      :title="
                        data.company && data.company.company_logo
                          ? data.company.company_logo
                          : ''
                      "
                      :src="
                        data.company && data.company.company_logo
                          ? data.company.company_logo
                          : '/img/new.png'
                      "
                      alt=""
                      height="100"
                      width="100"
                      class="md-size"
                    />
                  </div>
                  <div
                    v-if="data.is_company_logo == 0"
                    class="col-lg-3 col-sm-3 my-auto"
                    style="cursor: pointer"
                    v-on:click="openLink(data.news_link)"
                  >
                    <img
                      :title="
                        data.publication && data.publication.name
                          ? data.publication.name
                          : ''
                      "
                      :src="
                        data.publication && data.publication.publication_logo
                          ? data.publication.publication_logo
                          : ''
                      "
                      height="100"
                      width="100"
                      class="md-size"
                    />
                  </div>

                  <div class="col-lg-9 col-sm-9 my-auto calibri">
                    {{ data.news_title }}
                  </div>
                  <hr />
                </div>
              </div>
              <div
                class="col-12 title clearfix pb-2"
                style="display: flex; justify-content: end"
              >
                <a
                  v-if="newsList.length > 5"
                  style="cursor: pointer"
                  class="pull-right font16"
                  v-on:click="showDetails('NEWS')"
                  >View More</a
                >
              </div>
            </div>
            <div
              class="gery-box mt-5"
              v-show="
                detail.company_clusters && detail.company_clusters.length > 0
              "
            >
              <div class="title clearfix">Awards & Recognition</div>
              <div
                v-for="(data, index) in detail.company_clusters"
                :key="index"
              >
                <div class="row pb-2" v-if="index <= 4">
                  <div
                    class="col-lg-3 col-sm-3 my-auto"
                    style="cursor: pointer"
                    v-on:click="openLink(data.source_url)"
                  >
                    <img
                      :title="
                        data.publication && data.publication.name
                          ? data.publication.name
                          : ''
                      "
                      :src="
                        data.publication && data.publication.publication_logo
                          ? data.publication.publication_logo
                          : '/img/new.png'
                      "
                      alt=""
                      height="100"
                      width="100"
                      class="md-size"
                    />
                  </div>
                  <div class="col-lg-9 col-sm-9 my-auto calibri">
                    {{ data.name }}
                  </div>
                </div>
              </div>
              <div
                class="col-12 title clearfix pb-2"
                style="display: flex; justify-content: end"
              >
                <a
                  v-if="detail.company_clusters.length > 5"
                  style="cursor: pointer"
                  class="pull-right font16"
                  v-on:click="showDetails('LISTING')"
                  >View More</a
                >
              </div>
            </div>
            <div
              class="gery-box"
              style="background: #fff; border: 0"
              v-show="detail.videos && detail.videos.length > 0"
            >
              <div class="row gery-box">
                <div class="col-12 title clearfix">Executive Videos</div>
                <div class="row">
                  <div
                    style="width: 200px"
                    class="mt-3 mr-2 ml-2"
                    v-for="(data, index) in youtubeVideo"
                    :key="index"
                  >
                    <YouTube
                      v-if="data.video_url.includes('youtube')"
                      :id="'youtube_' + index"
                      width="200"
                      height="112"
                      :src="data.video_url"
                    />
                    <video v-else controls>
                      <source
                        :src="data.video_url"
                        type="video/mp4"
                        width="200"
                        height="100"
                      />
                    </video>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-4">
            <ELGrid
              :id="id"
              :limit="10"
              :type="'SIMILAR'"
              title="Similar Companies"
              @show-details="showDetails('SIMILAR')"
              @detail="getDetails('SIMILAR', $event)"
            ></ELGrid>
            <ELGrid
              :id="id"
              :limit="10"
              :type="'CURRENT'"
              title="Current Executives"
              @show-details="showDetails('CURRENT')"
              @detail="getDetails('CURRENT', $event)"
            ></ELGrid>
            <ELGrid
              :id="id"
              :limit="10"
              :type="'PAST'"
              title="Past Executives"
              @show-details="showDetails('PAST')"
              @detail="getDetails('PAST', $event)"
            ></ELGrid>

            <div
              class="gery-box mt-5"
              v-if="detail.interting_bite && detail.interting_bite.length > 0"
            >
              <div class="title clearfix">Interesting Bites</div>
              <div v-for="(data, index) in detail.interting_bite" :key="index">
                <div class="row pb-2" v-if="index <= 4">
                  <div
                    v-if="data.is_company_logo == 1"
                    class="col-lg-3 col-sm-3 my-auto"
                    style="cursor: pointer"
                    v-on:click="openLink(data.news_url)"
                  >
                    <img
                      :title="
                        data.company && data.company.company_name
                          ? data.company.company_name
                          : ''
                      "
                      :src="
                        data.company && data.company.company_logo
                          ? data.company.company_logo
                          : '/img/company.png'
                      "
                      height="100"
                      width="100"
                      class="md-size"
                    />
                  </div>
                  <div
                    v-if="data.is_company_logo == 0"
                    class="col-lg-3 col-sm-3 my-auto"
                    style="cursor: pointer"
                    v-on:click="openLink(data.news_url)"
                  >
                    <img
                      :title="
                        data.publication && data.publication.name
                          ? data.publication.name
                          : ''
                      "
                      :src="
                        data.publication && data.publication.publication_logo
                          ? data.publication.publication_logo
                          : ''
                      "
                      height="100"
                      width="100"
                      class="md-size"
                    />
                  </div>

                  <div class="col-lg-9 col-sm-9 my-auto calibri">
                    {{ $helperService.getText(data.bites, 100) }}
                  </div>

                  <hr />
                </div>
              </div>
              <div
                class="title clearfix"
                style="margin: 0 !important; padding: 0 !important"
              >
                <a
                  v-if="detail.interting_bite.length > 5"
                  style="cursor: pointer"
                  class="pull-right font16"
                  v-on:click="showDetails('BITES')"
                  >View More</a
                >
              </div>
            </div>
            <!-- <div
              class="gery-box mt-3"
              v-if="detail.commentry && detail.commentry.length > 0"
            >
              <div class="title clearfix">Recent Commentary</div>
              <div v-for="(data, index) in detail.commentry" :key="index">
                <div class="row pb-2 mt-3" v-if="index <= 4">
                  <div
                    class="col-lg-3 col-sm-3 my-auto"
                    style="cursor: pointer"
                    v-on:click="openLink(data.source)"
                  >
                    <img
                      :title="
                        data.executive && data.executive.executive_name
                          ? data.executive.executive_name
                          : ''
                      "
                      :src="
                        data.executive && data.executive.executive_logo
                          ? data.executive.executive_logo
                          : data.executive && data.executive.gender == 'male'
                          ? '/img/male.png'
                          : data.executive && data.executive.gender == 'female'
                          ? '/img/female.png'
                          : '/img/user-pic.png'
                      "
                      height="100"
                      width="100"
                      class="md-size"
                    />
                  </div> -->
                  <!-- <div
                    v-if="data.is_company_logo == 1"
                    class="col-lg-3 col-sm-3 my-auto"
                    style="cursor: pointer"
                    v-on:click="openLink(data.source)"
                  >
                    <img
                      :title="
                        data.company && data.company.company_name
                          ? data.company.company_name
                          : ''
                      "
                      :src="
                        data.company && data.company.company_logo
                          ? data.company.company_logo
                          : '/img/new.png'
                      "
                      height="100"
                      width="100"
                      class="md-size"
                    />
                  </div>
                  <div
                    v-if="data.is_company_logo == 0"
                    class="col-lg-3 col-sm-3 my-auto"
                    style="cursor: pointer"
                    v-on:click="openLink(data.source)"
                  >
                    <img
                      :title="
                        data.publication && data.publication.name
                          ? data.publication.name
                          : ''
                      "
                      :src="
                        data.publication && data.publication.publication_logo
                          ? data.publication.publication_logo
                          : ''
                      "
                      height="100"
                      width="100"
                      class="md-size"
                    />
                  </div> -->

                  <!-- <div class="col-lg-9 col-sm-9 my-auto calibri">
                    {{ $helperService.getText(data.comment, 100) }}
                  </div>

                  <hr />
                </div>
              </div>
              <div
                class="title clearfix"
                style="margin: 0 !important; padding: 0 !important"
              >
                <a
                  v-if="detail.commentry.length > 5"
                  style="cursor: pointer"
                  class="pull-right font16"
                  v-on:click="showDetails('COMMENTRY')"
                  >View More</a
                >
              </div>
            </div> -->
          </div>
        </div>
        <ErrorComponent @retry="getDetail(0)" ref="errorComponent" />
      </div>
    </div>
  </div>
</template>
<script>
import Chart from "chart.js/auto";
import CountryFlag from "vue-country-flag-next";
import ELGrid from "../../components/ELGrid.vue";
import YouTube from "vue3-youtube";
import ErrorComponent from "@/components/ErrorComponent";
export default {
  name: "Company Detail",
  components: {
    CountryFlag,
    YouTube,
    ELGrid,
    ErrorComponent,
  },
  watch: {
    "$route.fullPath"() {
      this.id = this.$route.params.id;
      this.ExecutiveMovesPerDay = [];
      this.ExecutiveMoves = [];
      this.ExecutivePerIndustry = [];
      this.youtubeVideo = [];
      this.getExecutiveMoveByYear();
      this.getExecutiveMoveByregion();
      this.getExecutiveMoveByIndustry();
      this.getCompanyOrzanizationStructure();
      this.getCompanyNewList();
      this.investedCompanyExecutiveList = [];
      this.getInvestedCompanyExecutiveList();
      this.getCompanyDealsList();
      this.getDetail();
    },
  },
  data() {
    return {
      id: this.$route.params.id,
      loader: false,
      is_headquater: false,
      detail: {
        company_clusters: [],
      },
      newsList: [],
      dealsList: [],
      structureList: [],
      structure_count: 0,
      ExecutiveMoves: [],
      ExecutiveMovesPerDay: [],
      investedCompanyExecutiveList: [],
      youtubeVideo: [],
      ExecutivePerIndustry: [],
      commentary: [],
      showingFullText: false,
      moves: undefined,
      region: undefined,
      industry: undefined,
      regions: [],
      job_functions: [],
      topics: [],
      executive_count_company_wise: [],
      button_color_arr: [ '#D7FFCE', '#D1FFEC', '#BEF1F6', '#FFF4D1', '#EFEFEF', '#D2ECFF',
                          '#F5ECCF', '#EBECE6', '#E1FAFF', '#ECE1D5', '#FFE2D1', '#F3D6CE'
                        ],
    };
  },
  computed: {
    formattedBody() {
      if (this.showingFullText) {
        return this.detail.company_desc;
      }

      return `${this.detail.company_desc.slice(0, 200).trim()}...`;
    },
  },
  mounted() {
    this.getJobFuctionlistExecutiveWise();
    this.getRegionListExecutiveWise();
    this.getTopiclistExecutiveWise();
    this.getCompanyNewList();
    this.getInvestedCompanyExecutiveList();
    this.getCompanyDealsList();

    if (this.id) {
      this.getDetail();
    }
    this.getExecutiveMoveByYear();
    this.getExecutiveMoveByregion();
    this.getExecutiveMoveByIndustry();
    this.getCompanyOrzanizationStructure();
    this.getExecutiveCountCompanyWise();
  },

  methods: {
    goTo(url) {
      if (url) {
        window.open(url);
      }
    },
    getExecutiveCountCompanyWise() {  // The graph shows the scale of executives recently hired from past companies, illustrating how many employees from the same previous company are now working in current company.
      if (this.id) {
        this.$api
          .getAPI({
            _action: "/company_wise/executive_count/graph/" + this.id,
          })
          .then((res) => {
            this.executive_count_company_wise = res.list;
            if (this.executive_count_company_wise.length > 0) {
              this.renderExecutiveHiringGraph();
            } else {
              this.executive_count_company_wise = [];
            }
          })
          .catch(() => {});
          
      }
    },
    renderExecutiveHiringGraph() {
      const labels = this.executive_count_company_wise.map((item) => item.previous_company_name);
      // const labels = this.executive_count_company_wise.map((item) => ({
      //   name: item.previous_company_name,
      //   logo: item.previous_company_logo,
      // }));
      const maleCounts = this.executive_count_company_wise.map((item) => Number(item.male_count));
      const femaleCounts = this.executive_count_company_wise.map((item) => Number(item.female_count));

      const ctx = document.getElementById("hiringGraph").getContext("2d");

      new Chart(ctx, {
        type: "bar",
        data: {
          labels: labels,
          datasets: [
            {
              label: "Male",
              data: maleCounts,
              backgroundColor: "#7fe7fa", //blue
            },
            {
              label: "Female",
              data: femaleCounts,
              backgroundColor: "#d6a5f6",//pink
            },
          ],
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: "top",
            },
          },
          scales: {
            x: {
              stacked: true,
              ticks: {
                  font: {
                      size: 9, // set the font size for x-axis labels
                  }
              },
              grid: {
                display: false
              }  
            },
            y: {
              stacked: true,
              beginAtZero: true,
              grid: {
                display: false
              }  
            },
          },
        },
        
      });
    }, 
    getRegionListExecutiveWise() {
      if (this.id) {
        this.$api
          .getAPI({
            _action: "/executive_wise/region_list/" + this.id,
          })
          .then((res) => {
            this.regions = res.list;
            this.is_headquater = res.is_headquater;
          })
          .catch(() => {});
      }
    },
    getJobFuctionlistExecutiveWise() {
      if (this.id) {
        this.$api
          .getAPI({
            _action: "/executive_wise/jobfunction_list/" + this.id,
          })
          .then((res) => {
            this.job_functions = res.list;
          })
          .catch(() => {});
      }
    },
    getTopiclistExecutiveWise() {
      if (this.id) {
        this.$api
          .getAPI({
            _action: "/executive_wise/interestingbites/topic_list/" + this.id,
          })
          .then((res) => {
            this.topics = res.list;
          })
          .catch(() => {});
      }
    },
    getCompanyOrzanizationStructure() {
      var obj = {};
      obj.limit = 4;
      if (this.id) {
        this.$api
          .getAPI({
            _action:
              "/company/organization/structure/pagination/list/" + this.id,
            _body: obj,
          })
          .then((res) => {
            this.structure_count = res.count;
            this.structureList = res.list;
          })
          .catch(() => {});
      }
    },
    getInvestedCompanyExecutiveList() {
      this.investedCompanyExecutiveList = [];
      var obj = {};
      obj.limit = 10;
      if (this.id) {
        this.$api
          .getAPI({
            _action: "/invested/company-executive/list/" + this.id,
            _body: obj,
          })
          .then((res) => {
            if (res.investedCompanylist && res.investedCompanylist.length > 0) {
              for (var j in res.investedCompanylist) {
                var obj_new = {};
                obj_new.name = res.investedCompanylist[j].company_name;
                obj_new.logo = res.investedCompanylist[j].company_logo;
                obj_new.news_url = res.investedCompanylist[j].news_url;
                this.investedCompanyExecutiveList.push(obj_new);
              }
            }
            if (
              res.investedExecutivelist &&
              res.investedExecutivelist.length > 0
            ) {
              for (var i in res.investedExecutivelist) {
                var obj = {};
                obj.name = res.investedExecutivelist[i].executive_name;
                obj.logo = res.investedExecutivelist[i].executive_logo;
                obj.news_url = res.investedExecutivelist[i].news_url;
                this.investedCompanyExecutiveList.push(obj);
              }
            }
            if (res.investorCompanylist && res.investorCompanylist.length > 0) {
              for (var k in res.investorCompanylist) {
                var obj_neww = {};
                obj_neww.name = res.investorCompanylist[k].company_name;
                obj_neww.logo = res.investorCompanylist[k].company_logo;
                obj_neww.news_url = res.investorCompanylist[k].news_url;
                this.investedCompanyExecutiveList.push(obj_neww);
              }
            }
          })
          .catch(() => {});
      }
    },
    addToFavorite(data) {
      var obj = {};
      obj.company_id = data.id;
      this.$api
        .putAPI({
          _action: "/add/company/fav",
          _body: obj,
        })
        .then((res) => {
          this.$notify({
            type: "success",
            text: res.message,
          });
          return (this.detail.is_fav = this.detail.is_fav == 1 ? 0 : 1);
          // this.getList(0);
          // this.$router.push("/company");
        })
        .catch(() => {});
    },
    showDetails(type) {
      if (type == "CURRENT") {
        this.$router.push("/company-executive/" + "CURRENT/" + this.id);
      } else if (type == "PAST") {
        this.$router.push("/company-executive/" + "PAST/" + this.id);
      } else if (type == "SIMILAR") {
        this.$router.push("/similar-company/" + this.id);
      } else if (type == "COMMENTRY") {
        this.$router.push("/similar-commentaries/" + this.id);
      } else if (type == "BITES") {
        this.$router.push("/similar-interesting-bites/" + this.id);
      } else if (type == "NEWS") {
        this.$router.push("/similar-news/" + this.id);
      } else if (type == "DEALS") {
        this.$router.push("/similar-deals-transactions/" + this.id);
      } else if (type == "LISTING") {
        this.$router.push("/listing");
      }
    },
    appliedFilterOnExecutive(type, itemId) {
      this.$router.push("/company-executive-region/" + type + "/" + this.id + "/" + itemId); 
    },
    openLink(url) {
      window.open(url);
    },
    getDetails(type, id) {
      if (type == "CURRENT" || type == "PAST") {
        this.$router.push("/executive-detail/" + id);
      } else {
        this.$router.push("/company-detail/" + id);
        this.id = id;
        this.getDetail();
      }
    },
    getDetail() {
      if (this.id) {
        this.loader = true;
        this.$refs.errorComponent.updateFormLoader(true);
        this.$api
          .getAPI({
            _action: "/company/detail/" + this.id,
          })
          .then((res) => {
            if (res.company_type) {
              res.company_type =
                res.company_type.charAt(0).toUpperCase() +
                res.company_type.substring(1).toLowerCase();
            }
            this.detail = res;

            var index = 0;
            var $this = this;
            for (var k in $this.detail.videos) {
              if (!$this.detail.videos[k].video_url) {
                $this.detail.videos.splice(k, 1);
              }
            }
            for (var i in this.detail.videos) {
              setTimeout(() => {
                $this.youtubeVideo.push($this.detail.videos[index++]);
              }, 700 * i + 1);
            }
            this.loader = false;
            if (this.$refs.errorComponent) {
              // this.$refs.errorComponent.setData(res, "No data available");
              this.$refs.errorComponent.updateFormLoader(false);
            }
          })
          .catch(() => {
            this.loader = false;
            if (this.$refs.errorComponent) {
              // this.$refs.errorComponent.setData(0, undefined, "");
              this.$refs.errorComponent.updateFormLoader(false);
            }
          });
      }
    },
    getCompanyNewList() {
      var obj = {};
      obj.limit = 10;
      if (this.id) {
        this.$api
          .getAPI({
            _action: "/company/news/pagination/list/" + this.id,
            _body: obj,
          })
          .then((res) => {
            this.newsList = res.list;
          })
          .catch(() => {});
      }
    },
    getCompanyDealsList() {
      var obj = {};
      obj.limit = 10;
      if (this.id) {
        this.$api
          .getAPI({
            _action: "/company/deals/pagination/list/" + this.id,
            _body: obj,
          })
          .then((res) => {
            this.dealsList = res.list;
          })
          .catch(() => {});
      }
    },
    getExecutiveMoveByregion() {
      if (this.id) {
        var obj = {
          company_id: this.id,
        };
        this.$api
          .getAPI({
            _action: "/executive/moves/region",
            _body: obj,
          })
          .then((res) => {
            this.ExecutiveMovesPerDay = res;
            this.getGraphExecutiveMoveByregion();
          })
          .catch(() => {});
      }
    },
    getGraphExecutiveMoveByregion() {
      this.labelsArr = [];
      this.revanueArr = [];

      for (var i = 0; i < this.ExecutiveMovesPerDay.length; i++) {
        this.labelsArr.push(this.ExecutiveMovesPerDay[i].title);
        var revanue = this.ExecutiveMovesPerDay[i].count;
        this.revanueArr.push(revanue);
      }
      if (this.region) {
        this.region.destroy();
      }
      const ctx = document.getElementById("bar-chart3").getContext("2d");
      // var t = this;
      this.region = new Chart(ctx, {
        type: "pie",
        data: {
          labels: this.labelsArr,
          datasets: [
            {
              data: this.revanueArr,
              backgroundColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
              ],
              borderColor: "#87CEEB",
              borderWidth: 1,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              display: false,
            },
            x: {
              display: false,
            },
          },
        },
      });
    },

    getExecutiveMoveByIndustry() {
      if (this.id) {
        var obj = {
          company_id: this.id,
        };
        this.$api
          .getAPI({
            _action: "/executive/moves/function",
            _body: obj,
          })
          .then((res) => {
            this.ExecutivePerIndustry = res;
            this.getGraphExecutiveMoveByIndustry();
          })
          .catch(() => {});
      }
    },
    getGraphExecutiveMoveByIndustry() {
      this.labelsArr = [];
      this.revanueArr = [];

      for (var i = 0; i < this.ExecutivePerIndustry.length; i++) {
        this.labelsArr.push(this.ExecutivePerIndustry[i].title);
        var revanue = this.ExecutivePerIndustry[i].count;
        this.revanueArr.push(revanue);
      }
      if (this.industry) {
        this.industry.destroy();
      }
      const ctx = document.getElementById("bar-chart5").getContext("2d");
      // var t = this;
      this.industry = new Chart(ctx, {
        type: "pie",
        data: {
          labels: this.labelsArr,
          datasets: [
            {
              data: this.revanueArr,
              backgroundColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
              ],
              borderColor: "#87CEEB",
              borderWidth: 1,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            y: {
              display: false,
            },
            x: {
              display: false,
            },
            // y: {
            //   beginAtZero: true,
            //   grid: {
            //     display: true,
            //   },
            //   ticks: {
            //     callback: function (label) {
            //       return t.$helperService.abbreviateNumber(label);
            //     },
            //   },
            // },
            // x: {
            //   grid: {
            //     display: false,
            //   },
            // },
          },
        },
      });
    },
    getExecutiveMoveByYear() {
      if (this.id) {
        var obj = {
          company_id: this.id,
        };
        this.$api
          .getAPI({
            _action: "/executive/moves/year",
            _body: obj,
          })
          .then((res) => {
            this.ExecutiveMoves = res;
            this.getGraphExecutiveMoveByYear();
          })
          .catch(() => {});
      }
    },
    getGraphExecutiveMoveByYear() {
      this.labelsArr = [];
      this.revanueArr = [];

      for (var i = 0; i < this.ExecutiveMoves.length; i++) {
        var month_name =
          this.$helperService.getText2(this.ExecutiveMoves[i].month, 3) +
          "-" +
          this.ExecutiveMoves[i].year;
        this.labelsArr.push(month_name);
        var revanue = Number(this.ExecutiveMoves[i].count);
        this.revanueArr.push(revanue);
      }
      if (this.moves) {
        this.moves.destroy();
      }
      const ctx = document.getElementById("bar-chart").getContext("2d");
      var t = this;
      this.moves = new Chart(ctx, {
        type: "bar",
        data: {
          labels: this.labelsArr,
          datasets: [
            {
              data: this.revanueArr,
              backgroundColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
              ],
              borderColor: "#87CEEB",
              borderWidth: 1,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            // y: {
            //   display: false,
            // },
            // x: {
            //   display: false,
            // },
            y: {
              beginAtZero: true,
              grid: {
                display: true,
              },
              max: Math.max(...t.revanueArr) ? Math.max(...t.revanueArr) : 10,
              min: Math.max(...t.revanueArr) ? Math.min(...t.revanueArr) : 0,
              ticks: {
                stepSize: 2,
              },
              display: true,
            },
            x: {
              display: false,
            },
          },
        },
      });
    },
  },
};
</script>

<style>
.normal-flag[data-v-60be6971] {
  margin: 0em -0.5em -1em -0em !important;
  margin-top: -0.4em !important;
  margin-right: -0.5em !important;
  margin-bottom: -1em !important;
  margin-left: 0em !important;
  transform: scale(0.5) !important;
  -ms-transform: scale(0.5) !important;
  -webkit-transform: scale(0.5) !important;
  -moz-transform: scale(0.5) !important;
}
.button-style-custom {
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 15px;
  font-size: 12px;
  color:black;
  margin: 2px;
  background-color: #D2ECFF;
}
</style>
