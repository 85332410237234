<template>
  <div>
    <section role="main" class="empty">
      <div class="container">
        <div class="row icon-tabs">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <router-link to="/executive" class="nav-link"
                ><img src="/img/icons/executives.png" alt="Executives" />
                Executives</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/company" class="nav-link"
                ><img src="/img/icons/companies.png" alt="Companies" />
                Companies</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/news" class="nav-link"
                ><img src="/img/icons/deals.png" alt="News" /> News</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/deals-transactions" class="nav-link"
                ><img src="/img/icons/deals.png" alt="Deals & Transactions" />
                Deals & Transactions</router-link
              >
            </li>
            <li class="nav-item">
              <router-link to="/listing" class="nav-link active"
                ><img src="/img/book.png" style="height: 23px" />
                Listing</router-link
              >
            </li>
            <!-- <li class="nav-item">
              <router-link to="/commentaries" class="nav-link"
                ><img src="/img/icons/commentaries.png" alt="Commentaries" />
                Commentaries</router-link
              >
            </li> -->
            <li class="nav-item">
              <router-link to="/interesting-bites" class="nav-link"
                ><img
                  src="/img/icons/interesting.png"
                  alt="Interesting Bites"
                />
                Interesting Bites</router-link
              >
            </li>
            <!-- NOTE: Temporary hide the open position tab. -->
            <!-- <li class="nav-item">
              <router-link to="/open-positons-new" class="nav-link"
                ><img src="/img/icons/position.png" alt="Open Position" /> Open
                Positons</router-link
              >
            </li> -->
            <li class="">
              <a
                class="nav-link"
                style="
                  font-weight: bold !important;
                  margin-top: 8px;
                  font-size: 13px;
                  color: #1f3342;
                  margin-left:290px;
                "
              >
                {{ count }}</a
              >
            </li>
          </ul>
          <div id="sixthmenu" class="tab-content mb-3">
            <div class="container px-0">
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <!-- <form class="col-12 form-search form-inline"> -->
                  <!-- <input
                    type="text"
                    class="search-query"
style="border: 0px none;width: 100%;"
                    v-model="searchObj.keyword"
                    v-on:keyup.enter="getList(0)"
                    placeholder="Search..."
                    
                  /> -->
                  <!-- </form> -->
                </div>
              </div>
              <!-- <hr /> -->
            </div>
            <div class="row">
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Industry</label>
                <Multiselect
                  mode="single"
                  trackBy="industry_name"
                  label="industry_name"
                  valueProp="id"
                  v-model="searchObj.industry_id"
                  :options="industryList"
                  searchable="true"
                  placeholder="Select"
                  @input="onIndustryChange"
                  @change="closesub($event)"
                  @search-change="searchIndustryName($event)"
                />
              </div>
              <!-- @input="onIndustryChange" (put it on industry on change then subsector will be work)--> 
              <div v-if="isShowSubsector" class="col-lg-2 col-sm-4 col-6">
                <label class="label">Subsector</label>
                <Multiselect
                  mode="single"
                  trackBy="industry_name"
                  label="industry_name"
                  valueProp="id"
                  v-model="searchObj.subsector_id"
                  :options="subsectorList"
                  searchable="true"
                  placeholder="Select"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Job Function</label>
                <Multiselect
                  mode="single"
                  trackBy="job_function_name"
                  label="job_function_name"
                  valueProp="id"
                  v-model="searchObj.job_function_id"
                  :options="jobFunctionList"
                  searchable="true"
                  placeholder="Select"
                  @search-change="searchJobFunctionName($event)"
                />
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">Country</label>
                <Multiselect
                  mode="single"
                  trackBy="country_name"
                  label="country_name"
                  valueProp="id"
                  placeholder="Select"
                  v-model="searchObj.country_id"
                  :options="countryList"
                  :noOptionsText="'Enter Text'"
                  searchable="true"
                  @search-change="searchCountryName($event)"
                />
                <!-- <select v-model="searchObj.gender" class="select">
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select> -->
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">List Type</label>
                <Multiselect
                  mode="single"
                  trackBy="value"
                  label="value"
                  valueProp="id"
                  v-model="searchObj.type"
                  :options="genders"
                  searchable="true"
                  placeholder="Select"
                />
                <!-- <select v-model="searchObj.gender" class="select">
                    <option value="">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select> -->
              </div>
              <div class="col-lg-2 col-sm-4 col-6" v-if="!isShowSubsector">
                  <label class="label">Year</label>
                  <Field
                    type="text"
                    name="year"
                    class="form-control"
                    placeholder="Year"
                    v-model="searchObj.curr_year"
                    v-slot="{ field }"
                  >
                    <Datepicker
                      autoApply
                      placeholder="Year"
                      v-bind="field"
                      v-model="searchObj.curr_year"
                      yearPicker
                    />
                  </Field>
                </div>
              <!-- <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">From Date</label>
                <datepicker
                  v-model="searchObj.from_date"
                  inputFormat="dd/MM/yyyy"
                  placeholder="To Date"
                  class="form-control"
                  style="background-color: white !important"
                  :clearable="false"
                  :upperLimit="searchObj.to_date ? searchObj.to_date : null"
                >
                </datepicker>
              </div>
              <div class="col-lg-2 col-sm-4 col-6">
                <label class="label">To Date</label>
                <datepicker
                  v-model="searchObj.to_date"
                  inputFormat="dd/MM/yyyy"
                  class="form-control"
                  placeholder="To Date"
                  :clearable="false"
                  style="background-color: white !important"
                  :lowerLimit="searchObj.from_date ? searchObj.from_date : null"
                >
                </datepicker>
              </div> -->
              <div class="col-lg-1 col-sm-4 col-6 mt-4">
                <!-- <label></label> -->
                <button type="button" v-on:click="getList(0)" class="apply-btn">
                  Apply
                </button>
              </div>
              <div class="col-lg-1 col-sm-4 col-6 mt-4">
                <!-- <label></label> -->

                <button
                  type="button"
                  v-on:click="ClearFilter()"
                  class="apply-btn"
                >
                  Reset
                </button>
                <!--  <div class="text-center"><a href="#" class="advance-search">Advance Search</a></div> -->
              </div>
            </div>
            <div class="row">
              <div class="col-lg-2 col-sm-4 col-6" v-if="isShowSubsector">
                  <label class="label">Year</label>
                  <Field
                    type="text"
                    name="year"
                    class="form-control"
                    placeholder="Year"
                    v-model="searchObj.curr_year"
                    v-slot="{ field }"
                  >
                    <Datepicker
                      autoApply
                      placeholder="Year"
                      v-bind="field"
                      v-model="searchObj.curr_year"
                      yearPicker
                    />
                  </Field>
                </div>
            </div>
            <hr />
            <div class="col-12 row mt-4">
              <div
                class="col-lg-4 col-sm-4"
                v-for="(data, index) in list"
                :key="index"
              >
                <div class="rectangle-box">
                  <div class="row" style="height: 115px">
                    <div
                      class="col-lg-3 col-sm-3 col-1 pr-2 text-center"
                      style="cursor: pointer"
                      v-on:click="$router.push('/view-listing/' + data.id)"
                    >
                      <a
                        style="cursor: pointer"
                        v-on:click="$router.push('/view-listing/' + data.id)"
                      >
                        <img
                          :title="
                            data.publication && data.publication.name
                              ? data.publication.name
                              : ''
                          "
                          :src="
                            data.publication &&
                            data.publication.publication_logo
                              ? data.publication.publication_logo
                              : ''
                          "
                          alt=""
                          class="md-img"
                        />
                      </a>
                    </div>
                    <div class="clrb">
                      <a
                        :title="data.name"
                        style="font-size: 12px; cursor: pointer"
                        v-on:click="$router.push('/view-listing/' + data.id)"
                        >{{ $helperService.getText2(data.name, 45) }}</a
                      >
                      <br />
                      <a
                        style="font-size: 12px; cursor: pointer"
                        v-on:click="$router.push('/view-listing/' + data.id)"
                        >{{
                          data.publication && data.publication.name
                            ? data.publication.name
                            : ""
                        }}</a
                      >
                      <br />
                      <a
                        style="color: blue; cursor: pointer; font-size: 12px"
                        v-on:click="openLink(data.source_url)"
                        >Source</a
                      >
                    </div>
                  </div>
                  <div
                    class="col-lg-12 col-sm-12"
                    style="cursor: pointer"
                    v-on:click="$router.push('/view-listing/' + data.id)"
                  >
                    <span class="update-date">{{
                      $helperService.getFormatedDate(data.created_at)
                    }}</span>
                  </div>
                </div>
              </div>

              <!-- <div class="col-lg-5 col-sm-4">
                <canvas
                  id="bar-chart5"
                  width="200"
                  height="200"
                  class="mt-3 ml-3"
                >
                </canvas>
              </div> -->
            </div>
            <ErrorComponent @retry="getList(0)" ref="errorComponent" />
            <div class="row mx-0 mb-4">
              <div class="col-lg-12 mt-5 text-center">
                <div class="page-bottom-pagination text-right">
                  <Pagination
                    @page-change="pageChange"
                    @items-per-page-change="itemsPerPageChange"
                    ref="listPagination"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Chart from "chart.js/auto";
import ErrorComponent from "@/components/ErrorComponent";
import Pagination from "@/components/Pagination";
import Multiselect from "@vueform/multiselect";
import { Field } from "vee-validate";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
export default {
  name: "News",
  components: {
    ErrorComponent,
    Pagination,
    Multiselect,
    Field,
    Datepicker,
  },
  data() {
    return {
      id: this.$route.params.id,
      loader: true,
      isShowSubsector: false,
      count: 0,
      genders: ["Company", "Executive"],
      searchObj: {
        from_date: "",
        to_date: "",
        industry_id: "",
        job_function_id: "",
        country_id: "",
        type: "",
        subsector_id: "",
        countryKeyword: "",
        curr_year: "",
      },
      country_obj: {},
      industryList: [],
      jobFunctionList: [],
      bitesMovesPerDay: [],
      countryList: [],
      company_ids: [],
      topicList: [],
      offset: -1,
      list: [],
      subsectorList: [],
      subsectorKeyword: "",
      industryKeyword: "",
      jobFunctionKeyword: "",
    };
  },
  mounted() {
    this.getList(0);
    this.industrylist();
    this.jobFunctionlist();
    // this.getbitesMoveByYear();
  },
  methods: {
    searchCountryName(e) {
      this.countryKeyword = e;
      this.CountryList();
    },
    CountryList() {
      if (this.countryKeyword) {
        this.country_obj.keyword = this.countryKeyword;
      }
      this.$api
        .getAPI({
          _action: "/country/list",
          _body: this.country_obj,
        })
        .then((res) => {
          if (this.countryKeyword) {
            this.countryList = res;
          } else {
            this.countryList = [];
          }          
        })
        .catch(() => {});
    },
    searchJobFunctionName(e) {
      this.jobFunctionKeyword = e;
      this.jobFunctionlist();
    },
    getbitesMoveByYear() {
      this.$api
        .getAPI({
          _action: "/bites/moves/year",
        })
        .then((res) => {
          this.bitesMovesPerDay = res;
          this.getGraphBitesMoveByDay();
        })
        .catch(() => {});
    },
    openLink(url) {
      window.open(url);
    },
    getGraphBitesMoveByDay() {
      this.labelsArr = [];
      this.revanueArr = [];

      for (var i = 0; i < this.bitesMovesPerDay.length; i++) {
        var topic_name = this.$helperService.getText2(
          this.bitesMovesPerDay[i].topic_name,
          10
        );
        this.labelsArr.push(topic_name);
        var revanue = this.bitesMovesPerDay[i].count;
        this.revanueArr.push(revanue);
      }
      if (this.sales) {
        this.sales.destroy();
      }
      const ctx = document.getElementById("bar-chart5").getContext("2d");
      this.myChart = new Chart(ctx, {
        type: "pie",
        data: {
          labels: this.labelsArr,
          datasets: [
            {
              data: this.revanueArr,
              backgroundColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgba(255, 206, 86, 1)",
                "rgba(75, 192, 192, 1)",
                "rgba(153, 102, 255, 1)",
                "rgba(255, 159, 64, 1)",
              ],
              borderColor: "#87CEEB",
              borderWidth: 0,
            },
          ],
        },
        options: {
          plugins: {
            legend: {
              display: true,
            },
          },
          scales: {
            y: {
              display: false,
            },
            x: {
              display: false,
            },
          },
        },
      });
    },
    searchIndustryName(e) {
      this.industryKeyword = e;
      this.industrylist();
    },
    jobFunctionlist() {
      var obj = {};
      if (this.jobFunctionKeyword) {
        obj.keyword = this.jobFunctionKeyword;
      }
      this.$api
        .getAPI({
          _action: "/job/function/list",
        })
        .then((res) => {
          this.jobFunctionList = res;
        })
        .catch(() => {});
    },
    closesub(e) {
      if (!e) {
        this.searchObj.subsector_id = "";
      }
    },
    onIndustryChange(selectedIndustryId) {
      if (selectedIndustryId) {
        this.subsectorList = [];
        this.isShowSubsector = false;
        this.getSubsectorList(selectedIndustryId);
      } else {
        this.isShowSubsector = false;
      }
    },
    industrylist() {
      this.$api
        .getAPI({
          _action: "/industry/list",
        })
        .then((res) => {
          this.industryList = res;
        })
        .catch(() => {});
    },
    getSubsectorList(id) {
      this.$api
        .getAPI({
          _action: "/subsectors/list/" + id,
        })
        .then((res) => {
          this.subsectorList = res.list;

          // Only show subsector dropdown if there are subsectors(of industry) available
          if (this.subsectorList.length > 0) {
            this.isShowSubsector = true;
          } else {
            this.isShowSubsector = false;
          }
        })
        .catch(() => {
          this.isShowSubsector = false;
        });
    },
    pageChange(offset) {
      this.getList(offset - 1);
    },
    geteComponydetail(id) {
      if (id) {
        this.$api
          .getAPI({
            _action: "/company/detail/" + id,
          })

          .then((res) => {
            this.searchObj.industry_id = res.industry_id;
          })
          .catch(() => {});
      }
    },
    itemsPerPageChange() {
      this.itemsPerPage = this.$refs.listPagination.itemsPerPage;
      this.getList(0);
    },
    ClearFilter() {
      this.searchObj = {};
      this.searchObj.type = "";
      this.searchObj.from_date = "";
      this.searchObj.to_date = "";
      this.searchObj.industry_id = "";
      this.searchObj.job_function_id = "";
      this.searchObj.type = "";
      this.searchObj.country_id = "";
      this.searchObj.subsector_id = "";
      this.isShowSubsector = false;
      this.searchObj.curr_year = "";
      this.getList(0);
    },
    getList(page) {
      this.$refs.errorComponent.updateListLoader(true);
      this.searchObj.offset = page;
      this.list = [];
      var obj = {};
      obj.offset = this.searchObj.offset;
      if (this.searchObj.from_date) {
        obj.from_date = moment(this.searchObj.from_date).format("DD-MM-YYYY");
      }
      if (this.searchObj.to_date) {
        obj.to_date = moment(this.searchObj.to_date).format("DD-MM-YYYY");
      }
      if (this.searchObj.industry_id) {
        obj.industry_id = this.searchObj.industry_id;
      }
      if (this.searchObj.subsector_id) {
        obj.subsector_id = this.searchObj.subsector_id;
      }
      if (this.searchObj.job_function_id) {
        obj.job_function_id = this.searchObj.job_function_id;
      }
      if (this.searchObj.country_id) {
        obj.country_id = this.searchObj.country_id;
      }
      if (this.searchObj.type) {
        obj.type = this.searchObj.type;
      }
      if (this.searchObj.curr_year) {
        obj.curr_year = this.searchObj.curr_year;
      }
      this.$api
        .getAPI({
          _action: "/cluster/pagination/list",
          _body: obj,
        })
        .then((res) => {
          this.loader = false;
          this.list = res.list;
          this.count = res.count;
          this.offset = res.next_offset;
          if (page == 0) {
            if (this.$refs.listPagination) {
              if (res.count != null && res.count > 0) {
                this.$refs.listPagination.setTotalCount(res.count);
              } else {
                this.$refs.listPagination.setTotalCount(0);
              }
            }
          }
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(res, "No listing list available");
            this.$refs.errorComponent.updateListLoader(false);
          }
        })
        .catch(() => {
          this.loader = false;
          if (this.$refs.errorComponent) {
            this.$refs.errorComponent.setData(0, undefined, "");
            this.$refs.errorComponent.updateListLoader(false);
          }
        });
    },
  },
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
.multiselect {
  background: #f0f0f0;
  height: 35px;
  border: 1px solid;
}

.multiselect-tag {
  background: #9cc338 !important;
}
</style>
