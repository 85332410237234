<template>
  <!-- <footer class="footer navbar-fixed-bottom" style="padding: 15px;">
    <div class="container">
      <div class="row">
        <div class="col-lg-6 col-12 col-sm-5 text-left">
          <span class="copyright"
            >&copy; Copyright 2022. ExecLibrary All Rights Reserved.</span
          >
        </div>
        <div class="col-lg-6 col-12 col-sm-7 txt-right">
          <strong class="hiddenm">Download Our App</strong>&nbsp;
          <a
            ><img src="/img/app-store.png" alt="" width="100" class="mr-2"
          /></a>
          <a ><img src="/img/google-play.png" alt="" width="100" /></a>
        </div>
      </div>
    </div>
  </footer> -->
  <footer>
    <section class="light-blue-bg">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-sm-6 col-12">
            <a href="#"><img src="img/footer-logo.png" alt="Execlibrary" /></a>
            <p class="para calibri-regular-white">
              Discover and profile millions of decision makers, track executive
              moves and many more!! Reach out to us for your customised research
              projects!
            </p>
          </div>
          <div class="col-lg-2 col-sm-3 col-6">
            <div class="title"><span>Quick Links</span></div>
            <div class="" style="cursor: pointer">
              <ul>
                <li v-if="token">
                  <a
                    class="calibri-regular-white"
                    v-on:click="$router.push('/executive')"
                    >Home</a
                  >
                </li>
                <li>
                  <a
                    class="calibri-regular-white"
                    v-on:click="$router.push('/teams')"
                    >Team</a
                  >
                </li>
                <li>
                  <a
                    class="calibri-regular-white"
                    v-on:click="$router.push('/about')"
                    >About Us</a
                  >
                </li>
                <li>
                  <a
                    class="calibri-regular-white"
                    v-on:click="$router.push('/pricing')"
                    >Pricing</a
                  >
                </li>
                <li>
                  <a
                    class="calibri-regular-white"
                    v-on:click="$router.push('/contact')"
                    >Contact Us</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-2 col-sm-3 col-6">
            <div class="title"><span>About Us</span></div>
            <div class="" style="cursor: pointer">
              <ul>
                <!-- <li><a class="calibri-regular-white">Blog</a></li> -->
                <li>
                  <a
                    class="calibri-regular-white"
                    v-on:click="$router.push('/GDPR')"
                    >GDPR</a
                  >
                </li>
                <li>
                  <a
                    class="calibri-regular-white"
                    v-on:click="$router.push('/privacy')"
                    >Privacy Policy</a
                  >
                </li>
                <li>
                  <a
                    class="calibri-regular-white"
                    v-on:click="$router.push('/terms')"
                    >Terms and Conditions</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="col-lg-2 col-sm-6 col-12"> -->
          <!-- <div class="title"><span>Download our app</span></div>
            <a href="https://play.google.com/store/apps?utm_source=apac_med&utm_medium=hasem&utm_content=Oct0121&utm_campaign=Evergreen&pcampaignid=MKT-EDR-apac-in-1003227-med-hasem-ap-Evergreen-Oct0121-Text_Search_SKWS-SKWS%7cONSEM_kwid_43700058906741035_creativeid_480912647742_device_c&gclid=Cj0KCQjwteOaBhDuARIsADBqReiy5l7dOxTs_1Cv1_7AFKAkVHC9_ugwJNTpzJxQ4oztGG8Yuf-A1TEaAssjEALw_wcB&gclsrc=aw.ds"
              ><img src="img/google-play-btn.png" alt="" class="space"
            /></a>
            <a href="https://www.apple.com/in/app-store/"
              ><img src="img/app-store-btn.png" alt="" class="space"
            /></a> -->
          <!-- </div> -->
          <div class="col-lg-4 col-sm-6 col-12">
            <div class="calibri-bold-white"><span>Contact Us</span></div>
            <p class="para calibri-regular-white">
              Get in touch to discuss your research needs and for a quote!
            </p>
            <ul class="social-network social-circle">
              <!-- <li>
                <a v-on:click="redirectTo()"
                  ><img src="img/facebook.png" alt=""
                /></a>
              </li> -->
              <li style="cursor: pointer">
                <a v-on:click="redirectTo('https://twitter.com/execlibrary')"
                  ><img src="img/twitter.png" alt=""
                /></a>
              </li>
              <li style="cursor: pointer">
                <a
                  ><img
                    v-on:click="
                      redirectTo(
                        'https://www.linkedin.com/company/execlibrary-com/'
                      )
                    "
                    src="/img/linkedin-color.png"
                    alt=""
                    style="
                      border-radius: 17px;
                      min-width: 23px !important;
                      height: 23px;
                    "
                /></a>
              </li>
              <!-- <li>
                <a v-on:click="redirectTo()"
                  ><img src="img/google_plus.png" alt=""
                /></a>
              </li> -->
              <!-- <li><a href="#" class="icoFacebook" title="Facebook"><i class="fa fa-facebook"></i></a> </li>
      <li><a href="#" class="icoTwitter" title="Twitter"><i class="fa fa-twitter"></i></a> </li>
      <li><a href="#" class="icoGoogle" title="Google Plus"><i class="fa fa-google-plus"></i></a> </li>-->
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="black-bg">
      <div class="container">
        <p class="copyright">
          &copy; Copyrights 2025 ExecLibrary. All rights reserved
        </p>
      </div>
    </section>
  </footer>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {
      token: localStorage.getItem("token"),
    };
  },
  mounted() {},
  methods: {
    redirectTo(url) {
      if (url) {
        window.open(url);
      }
    },
  },
};
// $(document).ready(function () {
//   $(".items").slick({
//     infinite: true,
//     slidesToShow: 3,
//     slidesToScroll: 3,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 2,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 480,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   });
// });
</script>
